<template>
  <div id="carousel-template">
    <!-- <div class="carousel is-medium has-carousel">
    <div id="hero_carousel" class="carousel">
      <div class="item-1">
        <img src="../assets/carousel/istockphoto-520703835-612x612.jpg" />
      </div>
      <div class="item-2">
        <img
          src="../assets/carousel/white-tir-truck-in-motion-driving-on-highway-2210x1473.jpg"
        />
      </div>
      <div class="item-3">
        <img src="../assets/carousel/download.jpg" />
      </div>
    </div> -->

    <agile
      :pauseOnHover="false"
      :navButtons="false"
      :fade="true"
      :autoplay="true"
      :dots="false"
      :infinite="true"
      :autoplay-speed="2000"
    >
      <div class="slide">
        <img
          class="carousel-img"
          src="../assets/carousel/white-tir-truck-in-motion-driving-on-highway-2210x1473.jpg"
        />
      </div>

      <div class="slide">
        <img
          class="carousel-img"
          src="../assets/carousel/istockphoto-520703835-612x612.jpg"
        />
      </div>

      <div class="slide">
        <img class="carousel-img" src="../assets/carousel/optimus.png" />
        <p class="carousel-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum
        </p>
      </div>
    </agile>
  </div>
</template>

<script>
// YourComponent.vue
import { VueAgile } from "vue-agile";

export default {
  name: "Carousel",
  props: {
    msg: String,
  },
  components: {
    agile: VueAgile,
  },
};
</script>

<style scoped>
.carousel-img {
  width: 100%;
  height: 720px;
}

.carousel-text {
  position: absolute;
  top: 500px;
  color: white;
  font-size: x-large;
  margin: 20px;
}
</style>
