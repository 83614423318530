<template>
<div id="searchTemplate">
  <div class="field has-addons has-addons-centered" id="searchElement">
    <p class="control">
      <span class="select">
        <select>
          <option>Buenos Aires</option>
          <option>Rosario</option>
          <option>Cordoba</option>
          <option>Mendoza</option>
        </select>
      </span>
    </p>
    <p class="control  is-expanded">
      <input class="input" type="text" placeholder="Calle Falsa 123" />
    </p>
    <p class="control">
      <a class="button is-success">
        Buscar
      </a>
    </p>
  </div>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
#searchTemplate {
    margin-top: 100px;
}

#searchElement {        
    /* padding-left: 150px; */
    /* padding-right: 150px; */
    position: absolute;
    margin-left: 7%;
    width: 85%;
    top: 200px;
    border-radius: 6px;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
    color: #4a4a4a;
}

/*...*/
</style>
