<template>
  <Navbar/>
  <sCarousel/>
  <Search/>
</template>

<script>
import 'bulma/css/bulma.css'

import Navbar from './components/navbar.vue'
import Search from './components/search.vue'
import sCarousel from './components/carousel.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Search,
    sCarousel
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
